<template>
  <b-row
    align-h="center"
    class="mt-5"
  >
    <b-col md="8">
      <b-card header="Reset Password">
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent="handleSubmit(resetPassword)">
            <validation-provider
              #default="validationContext"
              name="New Password"
              rules="required|password"
              vid="confirmed"
            >
              <b-form-group
                label="New Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="newPassword.password"
                  placeholder="New Password"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|confirmed:confirmed"
            >
              <b-form-group
                label="Confirm Password"
                label-for="password_confirmation"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="newPassword.password_confirmation"
                  placeholder="Confirm Password"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-button
              variant="primary"
              type="submit"
            >
              Reset
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="ml-1"
              @click="$router.push({name:'login'})"
            >
              Back
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ResetPassword',
  directives: {
    Ripple,
  },
  data() {
    return {
      newPassword: {
        email: this.$route.query.email,
        token: this.$route.query.token,
      },
    }
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()

    const {
      getValidationState,
    } = formValidation()

    return {
      successfulOperationAlert, showErrors, getValidationState,
    }
  },
  methods: {
    resetPassword() {
      this.$portalUsers.post('v1/password/reset', this.newPassword).then(() => {
        this.successfulOperationAlert('Password is reset successfully')
        this.$router.push({ name: 'login' })
      }).catch(err => {
        this.showErrors(err.response.data.meta)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
